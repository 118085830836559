import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from '../components/link'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Layout from '../components/layout'
import { Section } from '../components/sections'
import { Grid } from '../components/grid'

const gridGap = '16px'

const Project = styled.div`
  display: flex;
  position: relative;

  a {
    padding-top: 75%;
    background-image: url("${props => props.image}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    position: relative;
    color: #fff;
    text-decoration: none;

    div {
      padding: 40px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    h4,
    h6 {
      position: relative;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.25s ease;
    }

    h4 {
      margin-bottom: 10px;
    }

    &:after {
      content: "";
      background-color: rgba(0,0,15,0.75);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease;
    }

    &:hover {
      h4,
      h6,
      &:after {
        opacity: 1;
      }
    }
  }
`

const ProjectSubgrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gridGap};
`
const foldm = (r, j) =>
  r.reduce((a, b, i, g) => (!(i % j) ? a.concat([g.slice(i, i + j)]) : a), [])

const WorkPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      query WorkPageQuery {
        caseStudies: allMarkdownRemark(
          sort: { fields: frontmatter___published_on, order: DESC }
          filter: {
            fileAbsolutePath: { regex: "/(/_content/case_studies)/.*.md$/" }
          }
        ) {
          edges {
            node {
              fileAbsolutePath
              frontmatter {
                title
                tags
                images
              }
            }
          }
        }
        seo: file(
          relativePath: { eq: "seo.md" }
          sourceInstanceName: { eq: "data" }
        ) {
          data: childMarkdownRemark {
            frontmatter {
              image
              description
              siteTitle
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            {
              name: 'description',
              content: data.seo.data.frontmatter.description,
            },
            {
              name: 'og:image',
              content:
                'https://madebypioneer.com' + data.seo.data.frontmatter.image,
            },
          ]}
          title={`Work | ${data.seo.data.frontmatter.siteTitle}`}
        />
        <Section>
          <Grid offset={1} gap={gridGap}>
            {foldm(data.caseStudies.edges, 3).map((group, i) => {
              const isEven = i % 2 === 0
              const firstProject = group[0] ? group[0].node : null
              const secondProject = group[1] ? group[1].node : null
              const thirdProject = group[2] ? group[2].node : null

              const createPostUrl = fileName => {
                return fileName
                  .split('/')
                  .pop()
                  .replace('.md', '')
              }

              const createTags = tags => {
                let tagString = ''

                tags.map((tag, i) => {
                  tagString = tagString + tag
                  if (tags.length !== i + 1) tagString = tagString + ', '
                })

                return tagString
              }

              return (
                <Grid
                  key={i}
                  gap={gridGap}
                  desktop={{ columns: isEven ? '2fr 1fr' : '1fr 2fr' }}
                >
                  {isEven && (
                    <Project
                      image={
                        firstProject.frontmatter.images &&
                        firstProject.frontmatter.images[0]
                      }
                      style={{ height: '100%' }}
                    >
                      <Link
                        to={`/work/${createPostUrl(
                          firstProject.fileAbsolutePath
                        )}`}
                      >
                        <div>
                          <h4>{firstProject.frontmatter.title}</h4>
                          <h6>{createTags(firstProject.frontmatter.tags)}</h6>
                        </div>
                      </Link>
                    </Project>
                  )}

                  {(secondProject || !isEven) && (
                    <ProjectSubgrid>
                      <Project
                        image={
                          isEven
                            ? secondProject.frontmatter.images[0]
                            : firstProject.frontmatter.images[0]
                        }
                      >
                        <Link
                          to={`/work/${createPostUrl(
                            isEven
                              ? secondProject.fileAbsolutePath
                              : firstProject.fileAbsolutePath
                          )}`}
                        >
                          <div>
                            <h4>
                              {isEven
                                ? secondProject.frontmatter.title
                                : firstProject.frontmatter.title}
                            </h4>
                            <h6>
                              {createTags(
                                isEven
                                  ? secondProject.frontmatter.tags
                                  : firstProject.frontmatter.tags
                              )}
                            </h6>
                          </div>
                        </Link>
                      </Project>
                      {((!isEven && secondProject) ||
                        (isEven && thirdProject)) && (
                        <Project
                          image={
                            isEven
                              ? thirdProject.frontmatter.images[0]
                              : secondProject.frontmatter.images[0]
                          }
                        >
                          <Link
                            to={`/work/${createPostUrl(
                              isEven
                                ? thirdProject.fileAbsolutePath
                                : secondProject.fileAbsolutePath
                            )}`}
                          >
                            <div>
                              <h4>
                                {isEven
                                  ? thirdProject.frontmatter.title
                                  : secondProject.frontmatter.title}
                              </h4>
                              <h6>
                                {createTags(
                                  isEven
                                    ? thirdProject.frontmatter.tags
                                    : secondProject.frontmatter.tags
                                )}
                              </h6>
                            </div>
                          </Link>
                        </Project>
                      )}
                    </ProjectSubgrid>
                  )}

                  {!isEven && thirdProject && (
                    <Project
                      image={thirdProject.frontmatter.images[0]}
                      style={{ height: '100%' }}
                    >
                      <Link
                        to={`/work/${createPostUrl(
                          thirdProject.fileAbsolutePath
                        )}`}
                      >
                        <div>
                          <h4>{thirdProject.frontmatter.title}</h4>
                          <h6>{createTags(thirdProject.frontmatter.tags)}</h6>
                        </div>
                      </Link>
                    </Project>
                  )}
                </Grid>
              )
            })}
          </Grid>
        </Section>
      </Layout>
    )}
  />
)

export default WorkPage
